
.pricing-table {
  box-shadow: 0 0 15px LightGray;
  padding: 0 2rem 2rem 2rem;
}

.pricing-table > .pricing-plan > .plan-header {
  font-size: 2rem;
}

#servicebot-subscription-portal > div.servicebot-base.servicebot-embed.__pricing > div.servicebot-row > div.servicebot-col.__col-section-title.servicebot-col-xs-24.servicebot-col-sm-12 > h2 {
  font-size: 3rem;
  font-weight: 100;
  color: black;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:300');

.bestValueTitle {
  font-size: 16px;
  font-family: Libre Franklin;
  font-weight: 300;
  color: white;
  background-color: #B469FE;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.panel-group {
  background-color: white;
}

.priceMonth {
  font-size: 50%;
  font-weight: 100;
}

