@import "general.scss";

@import "blurred-box.scss";
@import "pricingtable.scss";
@import "serviceBotOverrides.scss";

#dialogConfirmUS {
  z-index: 10000;
}

.indexHeadingBeta {
  color:#3f3f3f;
  margin-left: 5rem;
  font-style: italic;
  font-size: 70%;
  vertical-align: top;
  display: inline-block;
}

.indexJoinDiv {
  vertical-align: top;
}