
#servicebot-subscription-portal button.servicebot-btn,
div.servicebot-modal-wrap button.servicebot-btn {
  background-color: #926EC8;
  border-color: transparent;
  color: #fff;
}

#servicebot-subscription-portal button.servicebot-btn:hover,
div.servicebot-modal-wrap button.servicebot-btn:hover {
  background-color: #8856d8;
}

div.servicebot-modal-wrap a {
  color: #133652;
}

div.servicebot-modal-wrap a:hover {
  color: #427196;
}

.servicebot-pricing-table .__tier .__body .__tier-plan.__plan-1 {
  padding-bottom: 0 !important;
}

.plan-item-servicebot {
  font-size: 1.25rem;
  font-weight: 400;
  color: black;
}

#pricing-1st-year-button {
  margin-top: 1rem;
}

